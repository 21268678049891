import AboutPageAboutUs from './components/AboutPageAboutUs';
import AboutPageDetails from './components/AboutPageDetails';
import AboutPageHeader from './components/AboutPageHeader';
import AboutPageHowToUse from './components/AboutPageHowToUse';
import background from '@/assets/main-about.jpg';
import HomePageTestimonials from '@/pages/Home/components/HomePageTestimonials.jsx';

const AboutPage = () => {
  return (
    <main>
      <AboutPageHeader />
      <AboutPageAboutUs />
      <AboutPageDetails />
      <AboutPageHowToUse />
      <div className='h-96 w-full bg-cover bg-fixed' style={{ backgroundImage: `url(${background})` }}></div>
      <HomePageTestimonials />
    </main>
  );
};

export default AboutPage;
