import first from '@/assets/advertisers/1.jpg';
import second from '@/assets/advertisers/2.jpg';
import third from '@/assets/advertisers/3.jpg';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdTable from '@/components/AdTable/AdTable.jsx';
import { useEffect, useRef } from 'react';
import { FaCheck } from 'react-icons/fa';

const LearnMorePage = () => {
  const { t } = useTranslation();
  const priceListRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#price-list') {
      const yOffset = -120; // Adjust this value as needed
      const y = priceListRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className='mb-24 sm:mb-48'>
      <div className='mt-24 sm:mt-48'>
        <p className='mb-4 block text-center text-3xl font-semibold lg:hidden lg:text-left'>{t('learnMorePage.whyTitle')}</p>
        <div className='flex items-center justify-center'>
          <div className='grid max-w-[1700px] grid-cols-1 gap-8 lg:grid-cols-2'>
            <div className='flex items-center px-4 lg:px-0'>
              <img src={first} alt='office' className='lg:rounded-r-2xl break17:rounded-l-2xl' />
            </div>
            <div className='flex w-full items-start justify-center lg:justify-start'>
              <div className='pl-4 pr-4 break55:w-160'>
                <p className='mb-4 hidden text-center text-3xl font-semibold lg:block lg:text-left'>{t('learnMorePage.whyTitle')}</p>
                <p className='mt-8 text-left text-base'>{t('learnMorePage.whyText')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-24 sm:mt-48'>
        <p className='mb-4 block text-center text-3xl font-semibold lg:hidden lg:text-left'>{t('learnMorePage.howTitle')}</p>
        <div className='flex items-center justify-center'>
          <div className='grid max-w-[1700px] grid-cols-1 gap-8 lg:grid-cols-2'>
            <div className='flex items-center px-4 lg:px-0'>
              <img src={second} alt='office' className='order-first lg:order-last lg:rounded-l-2xl break17:rounded-r-2xl' />
            </div>
            <div className='order-last flex w-full items-start justify-center lg:order-first lg:justify-start'>
              <div className='pl-4 pr-4 break55:w-160'>
                <p className='mb-4 hidden text-center text-3xl font-semibold lg:block lg:text-left'>{t('learnMorePage.howTitle')}</p>
                <div className='mt-8 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.how1')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.how2')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.how3')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.how4')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.how5')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-24 sm:mt-48'>
        <p className='mb-4 block text-center text-3xl font-semibold lg:hidden lg:text-left'>{t('learnMorePage.whyUseTitle')}</p>
        <div className='flex items-center justify-center'>
          <div className='grid max-w-[1700px] grid-cols-1 gap-8 lg:grid-cols-2'>
            <div className='flex items-center px-4 lg:px-0'>
              <img src={third} alt='office' className='lg:rounded-r-2xl break17:rounded-l-2xl' />
            </div>
            <div className='flex w-full items-start justify-center lg:justify-start'>
              <div className='pl-4 pr-4 break55:w-160'>
                <p className='mb-4 hidden text-center text-3xl font-semibold lg:block lg:text-left'>{t('learnMorePage.whyUseTitle')}</p>
                <div className='mt-8 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.whyUse1')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.whyUse2')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.whyUse3')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.whyUse4')}
                </div>
                <div className='mt-4 flex items-center justify-start text-left text-base'>
                  <div className='mr-4 inline-block rounded-full bg-primary-yellow p-1'>
                    <FaCheck className='text-black' size={16} />
                  </div>
                  {t('learnMorePage.whyUse5')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-24 px-4 sm:mt-48'>
        <div className='mt-8 flex items-center justify-center space-x-4'>
          <Link
            to={'/dashboard/addAds'}
            className='rounded-lg bg-black px-4 py-3 text-sm font-semibold leading-5 text-primary-yellow transition-all duration-300 hover:text-[#d3b000] break55:px-8 break55:text-base lg:px-12'
          >
            {t('placeAd')}
          </Link>
        </div>
      </div>

      <section ref={priceListRef} className='mt-24 flex items-center justify-center px-4 sm:mt-48'>
        <div className='mt-8 grid w-full max-w-4xl grid-cols-1 gap-16 md:grid-cols-2'>
          <div>
            <AdTable
              title={t('learnMorePage.pricingList.standardAd')}
              items={[
                { days: 30, price: '499,00' },
                { days: 90, price: '1.299,00' },
                { days: 180, price: '2.399,00' },
                { days: 365, price: '4.999,00' },
              ]}
            />
            <div className='py-4 text-sm'>
              {t('learnMorePage.pricingList.smallAdText1')}{' '}
              <a className={'inline whitespace-nowrap text-gray-500 hover:text-primary-yellow'} href='mailto:info@e-rent.rs'>
                info@e-rent.rs
              </a>{' '}
              {t('learnMorePage.pricingList.smallAdText2')}
            </div>
            <AdTable
              className='mt-8'
              title={t('learnMorePage.pricingList.premiumAd')}
              items={[
                { days: 30, price: '1.499,00' },
                { days: 90, price: '1.999,00' },
              ]}
            />
            <div className='py-4 text-sm'>
              {t('learnMorePage.pricingList.premiumAdText1')}{' '}
              <span className='whitespace-nowrap text-red-500'>{t('learnMorePage.pricingList.premiumAdText2')}</span>.
            </div>
            <AdTable
              className='mt-8'
              title={t('learnMorePage.pricingList.highlightedAd')}
              items={[
                { days: 7, price: '499,00' },
                { days: 15, price: '799,00' },
                { days: 30, price: '999,00' },
                { days: 180, price: '3.999,00' },
                { days: 365, price: '6.999,00' },
              ]}
            />
            <div className='py-4 text-sm'>
              {t('learnMorePage.pricingList.highlightedAdText1')}{' '}
              <span className='whitespace-nowrap bg-light-yellow shadow-md'>{t('learnMorePage.pricingList.highlightedAdText2')}</span>
            </div>
          </div>
          <div>
            <AdTable
              title={t('learnMorePage.pricingList.banners')}
              sections={[
                {
                  title: `${t('large')} 320X540`,
                  items: [
                    { days: 30, price: '5.999,00' },
                    { days: 90, price: '9.999,00' },
                    { days: 180, price: '13.999,00' },
                    { days: 365, price: '19.999,00' },
                  ],
                },
                {
                  title: `${t('medium')} 320X390`,
                  items: [
                    { days: 30, price: '4.999,00' },
                    { days: 90, price: '8.999,00' },
                    { days: 180, price: '12.999,00' },
                    { days: 365, price: '18.999,00' },
                  ],
                },
                {
                  title: `${t('small')} 320X270`,
                  items: [
                    { days: 30, price: '3.999,00' },
                    { days: 90, price: '7.999,00' },
                    { days: 180, price: '11.999,00' },
                    { days: 365, price: '17.999,00' },
                  ],
                },
              ]}
            />
            <div className='py-4 text-sm'>
              {t('learnMorePage.pricingList.bannerAdText1')}{' '}
              <a className={'inline whitespace-nowrap text-gray-500 hover:text-primary-yellow'} href='mailto:info@e-rent.rs'>
                info@e-rent.rs
              </a>
              . {t('learnMorePage.pricingList.bannerAdText2')}
            </div>
            <AdTable className={'mt-16'} title={t('learnMorePage.pricingList.category')} text={t('learnMorePage.pricingList.freeAd')} />
          </div>
        </div>
      </section>

      <div className='mt-24 flex items-center justify-center px-4 sm:mt-48'>
        <div className='w-full max-w-4xl'>
          <p className='mb-4 text-center text-base font-semibold sm:text-xl lg:text-left'>{t('learnMorePage.footer1')}</p>
          <p className='mb-4 text-center text-base font-semibold sm:text-xl lg:text-left'>{t('learnMorePage.footer2')}</p>
          <p className='mb-4 text-center text-base font-semibold sm:text-xl lg:text-left'>{t('learnMorePage.footer3')}</p>
        </div>
      </div>
    </div>
  );
};

export default LearnMorePage;
