import axios, { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { AdsFilter } from './components/AdsFilter/AdsFilter';
import { AdsPageHeader } from './components/AdsPageHeader';
import ProductService from '../../services/ProductService';
import BtnPageContainer from './components/BtnPageContainer';
import Card from '../../components/Card/Card';
import CardSkeleton from '../../components/Card/CardSkeleton';
import Search from '../../components/search/Search';
import Slider from './components/Slider/Slider';
import { removeCategory, removeSubCategories, storeCategory } from '../../store/categorySlice';
import { useDebounce } from '../../hooks/useDebounce';
import { useDispatch } from 'react-redux';

const ITEMS_PER_PAGE = 12;

const ProductPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [pageAds, setPageAds] = useState({ totalAds: 0, numOfPages: 0, currentPage: 1, ads: [] });
  const [currPage, setCurrPage] = useState(parseInt(searchParams.get('page')) || 1);
  const { pathname, state } = useLocation();
  const [filterObject, setFilterObject] = useState({
    limit: ITEMS_PER_PAGE,
    page: currPage,
    free: false,
    city: '',
    fields: '[]',
  });
  const [searchText, setSearchText] = useState(searchParams.get('q') || '');
  const search = useDebounce(searchText, 500);

  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allSubCategoriesFields, setAllSubCategoriesFields] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const getSliders = async () => {
    let res = await ProductService.getAllSliders();
    setSliders(res.data);
  };

  useEffect(() => {
    getSliders();
    setCurrPage(parseInt(searchParams.get('page') || 1));
  }, [location]);

  useEffect(() => {
    return () => {
      dispatch(removeCategory());
      dispatch(removeSubCategories());
    };
  }, []);

  const getAllSubcategoriesFields = async (id) => {
    try {
      const response = await ProductService.getSubCategoriesFields(id);
      const subCategoriesFields = response.data;
      setAllSubCategoriesFields(subCategoriesFields);
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterAds = useCallback(
    async (source) => {
      const { askForPrice, priceFrom, priceUpTo, ...filterObjectRest } = filterObject;
      const filtersQueryObject = {
        ...filterObjectRest,
        ...(priceFrom ? { priceFrom } : {}),
        ...(priceUpTo ? { priceUpTo } : {}),
        ...(askForPrice ? { askForPrice: 1 } : {}),
      };
      const fullPath = `products?${new URLSearchParams({ ...filtersQueryObject, page: parseInt(currPage) || 1 }).toString()}`;

      try {
        setIsLoading(true);
        const response = await ProductService.getAds(fullPath, { cancelToken: source.token });

        setPageAds({
          totalAds: response.data.totalCount,
          numOfPages: Math.ceil(response.data.totalCount / ITEMS_PER_PAGE),
          currentPage: currPage || 1,
          ads: response.data.data,
        });
        setIsLoading(false);
      } catch (error) {
        if (isAxiosError(error)) return;
        console.log(error);
        setIsLoading(false);
      }
    },
    [filterObject, currPage, pathname]
  );

  useEffect(() => {
    setPageAds((prev) => ({ ...prev, ads: [] }));
    let source = axios.CancelToken.source();
    getFilterAds(source);
    return () => {
      source.cancel();
    };
  }, [getFilterAds]);

  useEffect(() => {
    setSearchParams({ ['q']: searchText, ['page']: currPage }, { replace: true });
    setFilterObject((prev) => ({ ...prev, search: searchText }));
  }, [search]);

  useEffect(() => {
    setFilterObject((prev) => ({ ...prev, page: currPage }));
  }, [currPage]);

  useEffect(() => {
    if (state?.category) dispatch(storeCategory(state.category));
    if (state?.search) setSearchText(state?.search);
    const element = document.getElementById('filters');
    if (element && state?.search) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state?.search]);

  const handleFilterObject = (e) => {
    setFilterObject({ ...filterObject, [e.target.name]: e.target.value });
  };

  const CardSkeletonRender = () => {
    return [...Array(ITEMS_PER_PAGE)].map((_el, index) => <CardSkeleton key={index + '-' + currPage}></CardSkeleton>);
  };

  return (
    <main>
      <AdsPageHeader
        setFilterObject={setFilterObject}
        filterObject={filterObject}
        allCategories={allCategories}
        setAllCategories={setAllCategories}
        allSubCategories={allSubCategories}
        setAllSubCategories={setAllSubCategories}
        setAllSubCategoriesFields={setAllSubCategoriesFields}
        getAllSubcategoriesFields={getAllSubcategoriesFields}
        setSearchText={setSearchText}
      />
      <div id='filters' className='w-full px-4 pb-6 break16:flex break16:justify-center break16:px-11'>
        <div className='grid w-full max-w-[88rem] sm:flex'>
          <AdsFilter
            filterObject={filterObject}
            setFilterObject={setFilterObject}
            handleFilterObject={handleFilterObject}
            itemsPerPage={ITEMS_PER_PAGE}
            currPage={currPage}
            allSubCategoriesFields={allSubCategoriesFields}
          />
          <div className='w-full' id='search'>
            <Search
              setSearchText={setSearchText}
              numberOfAds={pageAds.totalAds}
              className='pb-6'
              setFilterObject={setFilterObject}
              filterObject={filterObject}
              searchText={searchText}
            />

            {/* <div className='flex w-full flex-wrap  place-items-center justify-around gap-5 gap-y-5 sm:px-6 md:grid  md:grid-cols-2 lg:grid-cols-3  '> */}

            {/* CARDS */}
            <div className='grid w-full place-items-center gap-[1rem] break4:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 break11:grid-cols-3'>
              {isLoading
                ? CardSkeletonRender()
                : pageAds.ads.map((ad) => {
                    return <Card ad={ad} key={ad._id + '-' + currPage}></Card>;
                  })}
            </div>

            {/* Pagination */}
            <div className='flex w-full justify-center py-4 pt-12 md:pl-6'>
              {pageAds.numOfPages > 1 && <BtnPageContainer pageAds={pageAds} setPageAds={setPageAds} setCurrPage={setCurrPage} />}
            </div>
          </div>
        </div>

        {/* BANNERI */}
        {/* ON DESKTOP VIEW */}
        <div className=' mt-[75px] hidden h-full w-full place-items-end gap-3 rounded-xl break55:grid-cols-2 break85:grid-cols-3 xl:grid-cols-4  2xl:grid break16:ml-8 break16:h-[128rem] break16:max-w-[19rem] break16:grid-cols-1'>
          <Slider
            className={'w-[320px]  rounded-xl bg-transparent break16:h-[540px]'}
            banners={sliders['slider1']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
            link={sliders['slider1']?.map((slider) => slider.link)}
          />
          <Slider
            className={' w-[320px] rounded-xl bg-transparent break16:h-[390px]'}
            banners={sliders['slider2']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
            link={sliders['slider2']?.map((slider) => slider.link)}
          />
          <Slider
            className={' w-[320px] rounded-xl bg-transparent break16:h-[390px]'}
            banners={sliders['slider3']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
            link={sliders['slider3']?.map((slider) => slider.link)}
          />
          <Slider
            className={'w-[320px] rounded-xl bg-transparent break16:h-[270px]'}
            banners={sliders['slider4']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
            link={sliders['slider4']?.map((slider) => slider.link)}
          />
          <Slider
            className={'w-[320px] rounded-xl bg-transparent break16:h-[270px]'}
            banners={sliders['slider5']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
            link={sliders['slider5']?.map((slider) => slider.link)}
          />
        </div>

        {/* ON SMALL SCREEN */}
        <div className='mt-[2.875rem] h-full  w-full place-items-end gap-4 rounded-xl 2xl:hidden   '>
          <div className='mx-auto flex w-full items-center justify-center gap-6'>
            <Slider
              className={' w-full rounded-xl bg-transparent break16:h-[270px]'}
              banners={sliders['slider4']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
              link={sliders['slider4']?.map((slider) => slider.link)}
            />
            <Slider
              className={' w-full rounded-xl bg-transparent break16:h-[540px]'}
              banners={sliders['slider1']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
              link={sliders['slider1']?.map((slider) => slider.link)}
            />
            <Slider
              className={'w-full rounded-xl bg-transparent break16:h-[270px]'}
              banners={sliders['slider5']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
              link={sliders['slider5']?.map((slider) => slider.link)}
            />
          </div>
          <div className='full mx-auto flex w-3/4 items-center justify-center gap-6 pt-10'>
            <Slider
              className={' w-full rounded-xl bg-transparent break16:h-[390px]'}
              banners={sliders['slider2']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
              link={sliders['slider2']?.map((slider) => slider.link)}
            />
            <Slider
              className={' w-full rounded-xl bg-transparent break16:h-[390px]'}
              banners={sliders['slider3']?.map((slider) => import.meta.env.VITE_IMAGE_URL + slider.img.substring(1))}
              link={sliders['slider3']?.map((slider) => slider.link)}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductPage;
