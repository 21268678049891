import { removeCategory, removeSubCategories, storeCategory, storeSubCategories } from '@/store/categorySlice.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import background from '@/assets/main-ads.jpg';

import ProductService from '@/services/ProductService';
import { BsArrowLeftCircle } from 'react-icons/bs';
import SingleCategory from './SingleCategory.jsx';
import getSlugText from '../../../utils/getSlugText.js';

import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '@/components/Header/Header.jsx';

const AdsPageHeader = ({
  setFilterObject,
  filterObject,
  allCategories,
  setAllCategories,
  allSubCategories,
  setAllSubCategories,
  setAllSubCategoriesFields,
  getAllSubcategoriesFields,
  setSearchText,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mainHeader, setMainHeader] = useState('ads');
  //const [categoryId, setCategoryId] = useState('');
  const [_, setSearchParams] = useSearchParams();
  const { category } = useSelector((state) => state.categoryStore);
  const navigate = useNavigate();

  const { categoryName, subCategoryName } = useParams();

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredButton(index);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const getAllCat = async () => {
    const response = await ProductService.getAllCategories();
    setAllCategories(response.data);

    const allCats = response.data;
    let category = null;
    if (categoryName) category = allCats.find((cat) => getSlugText(t(cat.key)) === categoryName);
    if (category) dispatch(storeCategory(category));
  };

  const getAllSubcategories = async () => {
    try {
      if (!category._id) return;
      const response = await ProductService.getSubCategories(category._id);
      const subCategories = response.data;
      dispatch(storeSubCategories(subCategories));
      setAllSubCategories(subCategories);

      let subCategoryNew = null;
      if (subCategoryName) subCategoryNew = subCategories.find((subCat) => getSlugText(t(subCat.name)) === subCategoryName);

      if (subCategoryNew) {
        setFilterObject((prev) => ({ ...prev, subCategory: subCategoryNew.name, page: 1 }));
        // setSearchParams((prev) => ({ ...prev, page: 1 }));
        // navigate(`/products/${getSlugText(t(category?.key))}/${getSlugText(t(subCategoryNew.name))}`, { state: { hash: 'search' } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCat();
    getAllSubcategories();
  }, [category._id]);

  useEffect(() => {
    if (!category?._id) return;
    setMainHeader(category?.key);
    setFilterObject({ ...filterObject, category: category?.name });
  }, [category]);

  return (
    <Header background={background} showCategories={false} categories={allCategories} subcategories={allSubCategories}>
      <div className='mt-32 grid place-content-center'>
        <h1 className='mb-32 text-center text-3xl font-medium text-primary-yellow md:mb-6 md:text-5xl xl:mb-14 xl:text-7xl'>
          {t(mainHeader)}
        </h1>
        {!category._id ? (
          <div className='mt-32 grid grid-cols-2 justify-items-center md:grid-cols-3 lg:flex lg:gap-12'>
            {allCategories.map((link) => {
              const { _id, name } = link;
              return (
                <SingleCategory
                  key={_id}
                  id={_id}
                  name={name}
                  categoryName={name}
                  setMainHeader={setMainHeader}
                  setFilterObject={setFilterObject}
                  filterObject={filterObject}
                  setSearchText={setSearchText}
                />
              );
            })}
          </div>
        ) : (
          <div className='mt-[32px]'>
            {allSubCategories.length > 0 ? (
              <div className='mx-auto grid place-items-center'>
                <div
                  className={`grid justify-center gap-x-4 gap-y-4 text-[0.75rem] font-medium text-primary-yellow 
      ${
        allSubCategories.length === 1
          ? 'grid-cols-1'
          : allSubCategories.length === 2
          ? 'grid-cols-2'
          : allSubCategories.length === 3
          ? 'grid-cols-3'
          : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6'
      }`}
                >
                  {allSubCategories.map((subCategory) => {
                    const { _id, name } = subCategory;
                    return (
                      <a key={_id} className='h-full'>
                        <button
                          type='button'
                          onClick={() => {
                            getAllSubcategoriesFields(_id);
                            setSearchText('');
                            setFilterObject((prev) => ({ ...prev, search: '', subCategory: name, page: 1 }));
                            setSearchParams({ ['q']: '', ['page']: 1 });
                            navigate(`/products/${getSlugText(t(category?.key))}/${getSlugText(t(name))}`, { state: { hash: 'search' } });
                          }}
                          onMouseEnter={() => handleMouseEnter(_id)}
                          onMouseLeave={() => handleMouseLeave(_id)}
                          className='relative h-full w-full rounded-full border-[1px] border-primary-yellow p-2 transition-all duration-200 hover:bg-primary-yellow hover:text-black'
                        >
                          {t(name).length > 25 ? `${t(name).slice(0, 20)} ...` : t(name)}
                          {hoveredButton === _id && (
                            <div className='absolute bottom-[120%] left-2/4 z-10 w-full -translate-x-[50%] rounded-md bg-primary-yellow p-4 text-black shadow-lg shadow-black transition-all duration-300'>
                              {t(name)}{' '}
                              <div className='absolute -bottom-1 right-2/4 h-4 w-4 translate-x-[50%] rotate-45 bg-primary-yellow'></div>
                            </div>
                          )}
                        </button>
                      </a>
                    );
                  })}
                </div>
                <div className='mt-10 grid w-full place-items-center '>
                  <button
                    className=' mx-auto flex items-center gap-2 rounded-full bg-primary-yellow px-4 py-2 '
                    onClick={() => {
                      setMainHeader('ads');
                      setAllSubCategories([]);
                      setAllSubCategoriesFields([]);
                      dispatch(removeSubCategories());
                      dispatch(removeCategory());
                      setFilterObject((prev) => ({ ...prev, category: '', fields: '[]', subCategory: '', page: 1 }));
                      setSearchText('');
                      navigate('/products');
                    }}
                  >
                    <BsArrowLeftCircle className='text-2xl' />
                    {t('allCategories')}
                  </button>
                </div>
              </div>
            ) : (
              <div className='mt-10 grid w-full place-items-center  '>
                <button
                  className='mx-auto flex items-center gap-2 rounded-full bg-primary-yellow px-4 py-2 '
                  onClick={() => {
                    setMainHeader('ads');
                    setAllSubCategories([]);
                    setAllSubCategoriesFields([]);
                    dispatch(removeSubCategories());
                    dispatch(removeCategory());
                    setFilterObject((prev) => ({ ...prev, category: '', fields: '[]', subCategory: '', page: 1 }));
                    setSearchText('');

                    navigate('/products');
                  }}
                >
                  <BsArrowLeftCircle className='text-2xl' />
                  {t('allCategories')}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </Header>
  );
};

export { AdsPageHeader };
