// icons

import exchangeIcon from '@/assets/icons/homePageCategories/homePageExchangeGiveAway.svg';
import getSlugText from '../../../utils/getSlugText';
import otherIcon from '@/assets/icons/homePageCategories/homePageOther.svg';
import { storeCategory } from '../../../store/categorySlice';
import turizamIcon from '@/assets/icons/homePageCategories/homePageTourismAndVacation.svg';
import ugostiteljstvoIcon from '@/assets/icons/homePageCategories/homePageCatering.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import uslugeIcon from '@/assets/icons/homePageCategories/homePageServices.svg';
import vozilaIcon from '@/assets/icons/homePageCategories/homePageVehiclesMachinesTools.svg';

// import { storeCategory } from '../../../store/categorySlice.jsx';

// import { useDispatch } from 'react-redux';

const SingleCategory = ({ id, name, filterObject, setFilterObject, setMainHeader, setSearchText }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let icon = '';
  let text = '';

  switch (name) {
    case 'Turizam i odmor':
      icon = turizamIcon;
      text = 'tourismAndVacation';
      break;
    case 'Ugostiteljstvo':
      icon = ugostiteljstvoIcon;
      text = 'catering';
      break;
    case 'Vozila, mašine i alati':
      text = 'vehiclesMachinesTools';
      icon = vozilaIcon;
      break;
    case 'Servisi':
      icon = uslugeIcon;
      text = 'services';
      break;
    case 'Menjam/Poklanjam':
      icon = exchangeIcon;
      text = 'exchangeGiveAway';
      break;
    case 'Razno':
      icon = otherIcon;
      text = 'other';
      break;
  }

  return (
    <button
      type='button'
      onClick={() => {
        setFilterObject({ ...filterObject, category: name, search: '' });
        setMainHeader(text);
        dispatch(storeCategory({ _id: id, name: name, key: text }));
        setSearchText('');
        navigate(`/products/${getSlugText(t(text))}`, { state: { hash: 'search' } });
      }}
      className='mb-4 grid justify-items-center'
    >
      <img src={icon} alt={name} className='mb-4 h-10 w-10 sm:h-16 sm:w-16 md:h-20 md:w-20 xl:h-24 xl:w-24' />
      <h1 className='text-center  text-sm font-semibold text-[#FED400] xl:text-[1.4rem] xl:leading-8'>{t(text)}</h1>
    </button>
  );
};

export default SingleCategory;
