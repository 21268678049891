import * as Yup from 'yup';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useEffect, useState } from 'react';

import ProductService from '@/services/ProductService';
import { FaStar } from 'react-icons/fa';
import { convertRSDCurrency } from '../../../../utils/currencyConverter';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/no-unknown-property */

// import { convertCurrency } from '../../utils/currencyConverter';

const AdsFilter = ({ filterObject, setFilterObject, allSubCategoriesFields }) => {
  const { t } = useTranslation();
  const [isVisibleBf, setIsVisibleBf] = useState(true);
  // const [isFree, setIsFree] = useState(false);
  const [isVisibleCity, setIsVisibleCity] = useState(true);
  const [isVisibleRating, setIsVisibleRating] = useState(true);
  const [isVisibleBool, setIsVisibleBool] = useState(true);
  const [cities, setCities] = useState([]);
  const [fields, setFields] = useState([]);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [_, setSearchParams] = useSearchParams();
  const { currency } = useSelector((state) => state.currencyStore);

  const { currencySymbol } = useSelector((state) => state.currencyStore);

  useEffect(() => {
    const getAllCities = async () => {
      try {
        const response = await ProductService.getCities(filterObject.category, filterObject.subCategory);
        const allCities = response.data;
        setCities(allCities);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCities();
  }, [filterObject.category, filterObject.subCategory]);

  const filterSchema = Yup.object().shape({
    free: Yup.boolean(),
    // priceFrom: Yup.number(),
    // priceUpTo: Yup.number().moreThan(Yup.ref('priceFrom'), `${t('priceFromPriceTo')}`),
    city: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      free: false,
      priceFrom: '',
      priceUpTo: '',
      askForPrice: false,
      city: '',
    },
    validationSchema: filterSchema,
    onSubmit: async (values) => {
      let convertedPriceFrom = values.priceFrom;
      let convertedPriceUpTo = values.priceUpTo;

      if (currency === 'rsd') {
        convertedPriceFrom = convertRSDCurrency(values.priceFrom, 'eur');

        convertedPriceUpTo = convertRSDCurrency(values.priceUpTo, 'eur');
      }
      setSearchParams((prev) => ({ ...prev, page: 1 }));
      let body = {
        ...values,
        priceFrom: convertedPriceFrom,
        priceUpTo: convertedPriceUpTo,
        rating: rating,
        fields: JSON.stringify(fields),
        page: 1,
      };
      setFilterObject((prev) => {
        return { ...prev, ...body };
      });
      window.scroll(0, 650);
    },
  });

  const showError = (name) => formik.errors[name] && formik.touched[name] && formik.errors[name];

  const handleResetForm = () => {
    formik.resetForm();
    setFields([]);
    setRating(null);
    setSearchParams((prev) => ({ ...prev, page: 1 }));
    setFilterObject((prev) => ({
      ...prev,
      page: 1,
      city: '',
      priceFrom: null,
      priceUpTo: null,
      rating: null,
      free: false,
      fields: '[]',
    }));
    window.scroll(0, 650);
  };

  const handleDropdownFields = (e) => {
    const indexOfField = fields.findIndex((f) => f[e.target.name] !== undefined);
    if (indexOfField !== -1) {
      if (e.target.value) {
        fields[indexOfField][e.target.name] = e.target.value;
        fields[indexOfField][e.target.name] = e.target.selectedOptions[0].value;
      } else {
        fields.splice(indexOfField, 1);
      }
      setFields([...fields]);
    } else {
      setFields((prev) => [...prev, { [e.target.name]: e.target.selectedOptions[0].value, type: e.target.attributes.inputtype.value }]);
    }
  };

  const handleInputFields = (e) => {
    const indexOfField = fields.findIndex((f) => f[e.target.name] !== undefined);
    if (indexOfField !== -1) {
      if (e.target.value) {
        fields[indexOfField][e.target.name] = e.target.value;
      } else {
        fields.splice(indexOfField, 1);
      }

      setFields([...fields]);
    } else {
      setFields((prev) => [...prev, { [e.target.name]: e.target.value, type: e.target.attributes.inputtype.value }]);
    }
  };

  const handleBooleanFields = (e) => {
    const indexOfField = fields.findIndex((f) => f[e.target.name] !== undefined);
    if (indexOfField !== -1) {
      if (e.target.checked) {
        fields[indexOfField][e.target.name] = e.target.value;
      } else {
        fields.splice(indexOfField, 1);
      }
      setFields([...fields]);
    } else {
      setFields((prev) => [...prev, { [e.target.name]: e.target.value, type: e.target.attributes.inputtype.value }]);
    }
  };

  return (
    // <div className='mt-[2.875rem] w-full pt-14 md:max-w-[21rem]'>
    <div id='filters' className='mt-[2.875rem] w-full pt-14 break55:mr-4 md:max-w-[21rem]'>
      {/* Start filter grey part */}
      {/* FORM */}
      <form onSubmit={formik.handleSubmit}>
        <div className='rounded-[0.5rem] bg-[#F5F5F5] px-[1.5rem] pt-[3.5rem] shadow-md md:w-full'>
          {/*Radio buttons*/}
          {filterObject.category !== 'Menjam/Poklanjam' ? (
            <>
              <div
                className={`flex cursor-pointer items-center justify-between hover:bg-[#ececec] hover:text-[#555555] ${
                  isVisibleBf ? '' : 'mb-4'
                }`}
                onClick={() => setIsVisibleBf((prev) => !prev)}
              >
                <h1 className=' text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C] hover:text-[#555555]'>{t('basicFilters')}</h1>
                {isVisibleBf ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              {/* {isVisibleBf ? (
                <div className='mt-[1.3125rem]'>
                  <div>
                    <input
                      type='radio'
                      id='price'
                      name='free'
                      value={false}
                      className='w-[0.8125rem] cursor-pointer'
                      onClick={() => setIsFree(false)}
                      onChange={formik.handleChange}
                      defaultChecked
                    />
                    <label htmlFor='price' className='ml-[10px]  text-[0.8125rem]'>
                      {t('price')}
                    </label>
                  </div>
                  <div className='pb-6'>
                    <input
                      type='radio'
                      id='free'
                      name='free'
                      value={true}
                      className='w-[0.8125rem] cursor-pointer'
                      onClick={() => setIsFree(true)}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor='free' className='ml-[10px]  text-[0.8125rem]'>
                      {t('free')}
                    </label>
                  </div>
                </div>
              ) : null} */}
              <hr className='border-[1px] border-[#D6D6D6]' />
              {/* Start price*/}
              {/* {!isFree ? ( */}
              <>
                <div className='w-full'>
                  <div className='mt-4 grid grid-cols-8 items-center justify-between gap-1 pb-4'>
                    <div className='col-span-3'>
                      <input
                        type='number'
                        name='priceFrom'
                        value={formik.values.priceFrom}
                        className='w-full rounded-full border-[1px] py-4 pl-4 pr-2 text-center placeholder:text-sm'
                        onChange={formik.handleChange}
                        placeholder={0 + ' ' + currencySymbol}
                        min='0'
                        onWheel={() => document.activeElement.blur()}
                      />
                    </div>
                    <div className='col-span-2 grid place-items-center'>
                      <p className='text-[12px] md:text-[14px]'>{t('price')}</p>
                      <p className='text-[12px] md:text-[14px]'>{t('fromTo')}</p>
                    </div>
                    <div className='col-span-3'>
                      <input
                        type='number'
                        name='priceUpTo'
                        // value={formik.values.priceUpTo}
                        className='w-full rounded-full border-[1px] py-4 pl-4 pr-2 text-center placeholder:text-sm'
                        onChange={formik.handleChange}
                        placeholder={0 + ' ' + currencySymbol}
                        min='0'
                        onWheel={() => document.activeElement.blur()}
                      />
                    </div>
                  </div>
                  <p className='mb-4 text-center text-[0.75rem] text-pink-500'>{showError('priceMax')}</p>
                </div>
                <div className='my-[12px] flex'>
                  <input
                    type='checkbox'
                    name={'askForPrice'}
                    checked={formik.values.askForPrice}
                    value={formik.values.askForPrice}
                    onChange={formik.handleChange}
                    className='h-[1.1875rem] w-[1.1875rem]'
                  />
                  <label className='pl-[5px]'>{t('askForPrice')}</label>
                </div>
                <hr className='border-[1px] border-[#D6D6D6]' />
              </>
              {/* ) : null} */}
            </>
          ) : null}
          {/* cities START */}
          <div className={`${isVisibleCity ? 'py-9' : 'py-4'}`}>
            <div
              onClick={() => setIsVisibleCity((prev) => !prev)}
              className='flex cursor-pointer  items-center justify-between hover:bg-[#ececec] hover:text-[#555555]'
            >
              <h1 className='cursor-pointer  text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C] hover:text-[#555555]'>
                {t('city')}
              </h1>
              {isVisibleCity ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {isVisibleCity ? (
              <div className='mt-6 w-full rounded-md border-[1px] border-[#7B7B7B] bg-white px-4 py-1'>
                <select className='w-full bg-white' value={formik.values.city} onChange={formik.handleChange} name='city'>
                  <option value='' className='bg-white  text-sm font-normal text-[#2C2C2C]'>
                    {t('allCities')}
                  </option>
                  {cities.map((city) => {
                    const { _id, name } = city;
                    return (
                      <option key={_id} value={name} className='bg-white  text-sm font-normal text-[#2C2C2C]'>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </div>
          <hr className='border-[1px] border-[#D6D6D6]' />
          {/* dynamic FIELDS */}
          <div className='py-2'>
            {allSubCategoriesFields.map((subCategoryField) => {
              // DROPDOWN FIELDS
              if (subCategoryField.type === 'dropdown') {
                return (
                  <div className='w-full py-2' key={subCategoryField.name}>
                    <div className='flex items-center justify-between'>
                      <label className='py-2  text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C]'>{t(subCategoryField.name)}</label>
                    </div>
                    <div className=' w-full rounded-md border-[1px] border-[#7B7B7B] bg-white px-4 py-1'>
                      <select
                        name={subCategoryField.name}
                        onChange={(e) => handleDropdownFields(e)}
                        inputtype={subCategoryField.type}
                        className='w-full bg-white'
                      >
                        <option value='' className='bg-white  text-sm font-normal text-[#2C2C2C]'>
                          {t('select')}
                        </option>
                        {subCategoryField.options.map((field, index) => (
                          <option value={field} key={field + index} className='bg-white  text-sm font-normal text-[#2C2C2C]'>
                            {t(field)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                );
              }
              // NUMBER FIELDS
              if (subCategoryField.type === 'number') {
                return (
                  <div className='mt-4 w-full' key={subCategoryField.name}>
                    <div className='flex items-center justify-between'>
                      <label className=' text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C] hover:text-[#555555]'>
                        {t(subCategoryField.name)}
                      </label>
                    </div>
                    <input
                      type='number'
                      onKeyDown={(evt) => ['e', 'E', '+', '-', ',', '.'].includes(evt.key) && evt.preventDefault()}
                      inputtype={subCategoryField.type}
                      name={subCategoryField.name}
                      value={formik.values.fields}
                      onChange={handleInputFields}
                      className='mt-4 w-full rounded-md border-[1px] border-[#7B7B7B] bg-white px-4 py-1'
                      min='0'
                    />
                  </div>
                );
              }
              // STRING FIELDS
              if (subCategoryField.type === 'string') {
                return (
                  <div className='mt-4 w-full' key={subCategoryField.name}>
                    <div className='flex items-center justify-between'>
                      <label className=' text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C]'>{t(subCategoryField.name)}</label>
                    </div>
                    <input
                      type='text'
                      inputtype={subCategoryField.type}
                      name={subCategoryField.name}
                      onChange={handleInputFields}
                      value={formik.values.fields}
                      className='mt-4 w-full rounded-md border-[1px] border-[#7B7B7B] bg-white px-4 py-1'
                    />
                  </div>
                );
              }
            })}
            {/* BOOLEAN FIELDS */}
            {allSubCategoriesFields.length > 0 && (
              <>
                <div className='mt-4'>
                  <div
                    onClick={() => setIsVisibleBool((prev) => !prev)}
                    className='flex cursor-pointer  items-center justify-between hover:bg-[#ececec] hover:text-[#555555]'
                  >
                    <h1 className='cursor-pointer  text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C] hover:text-[#555555]'>
                      {t('additionalFields')}
                    </h1>
                    {isVisibleBool ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </div>
                  {isVisibleBool ? (
                    <div className='pt-4'>
                      {allSubCategoriesFields.map((subCategoryField) => {
                        if (subCategoryField.type === 'boolean') {
                          return (
                            <div className='flex items-center gap-2 py-1' key={subCategoryField.name}>
                              <input
                                type='checkbox'
                                inputtype={subCategoryField.type}
                                name={subCategoryField.name}
                                value={subCategoryField.name}
                                checked={fields.some((f) => !!f[subCategoryField.name])}
                                onChange={handleBooleanFields}
                                className='h-4 w-4'
                              />
                              <label className=' text-[13px] font-light leading-[26px] text-[#2C2C2C]'>{t(subCategoryField.name)}</label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : null}
                </div>
                <hr className='border-[1px] border-[#D6D6D6]' />
              </>
            )}
          </div>
          {/* grade */}
          {filterObject.category !== 'Menjam/Poklanjam' ? (
            <>
              <div className={`${isVisibleRating ? 'py-9' : 'py-4'}`}>
                <div
                  onClick={() => setIsVisibleRating((prev) => !prev)}
                  className='flex cursor-pointer items-center justify-between hover:bg-[#ececec] hover:text-[#555555]'
                >
                  <h1 className='cursor-pointer  text-[1rem] font-semibold leading-[1.2rem] text-[#2C2C2C] hover:text-[#555555]'>
                    {t('rating')}
                  </h1>
                  {isVisibleRating ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </div>
                {/* stars */}
                {isVisibleRating ? (
                  <div className='flex w-full py-3'>
                    {[...Array(5)].map((star, i) => {
                      const ratingValue = i + 1;
                      return (
                        <label key={`star-${i}`}>
                          <input className='hidden' type='radio' name='rating' value={ratingValue} onClick={() => setRating(ratingValue)} />
                          <FaStar
                            onMouseEnter={() => setHover(ratingValue)}
                            onMouseLeave={() => setHover(null)}
                            color={ratingValue <= (hover || rating) ? '#FFD503' : '#e4e5e9'}
                            className='cursor-pointer text-xl 2xl:text-3xl'
                          />
                        </label>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        {/* End filter grey part */}
        <div className='mt-5 w-full'>
          <button
            type='submit'
            className='w-full rounded-lg bg-primary-yellow py-4  text-[0.875rem] font-semibold uppercase leading-[1.125rem] text-[#000000] shadow-md transition-all duration-300 hover:bg-[#424242]'
          >
            {t('searchAds')}
          </button>
        </div>
        <div className='mt-5 w-full'>
          <button
            type='button'
            onClick={() => {
              handleResetForm();
            }}
            className='w-full rounded-lg bg-[#000000] py-4  text-[0.875rem] font-semibold uppercase leading-[1.125rem] text-primary-yellow shadow-md transition-all duration-300 hover:bg-[#d3b000]'
          >
            {t('clearAllFilters')}
          </button>
        </div>
      </form>
    </div>
  );
};

export { AdsFilter };
