const Title = ({ title, className = '' }) => {
  return (
    <div className={`flex items-center drop-shadow ${className}`}>
      <p className='inline-block w-fit rounded-sm bg-primary-yellow bg-opacity-80 px-3 py-1 text-base font-medium uppercase text-black drop-shadow-xl lg:text-2xl'>
        {title}
      </p>
    </div>
  );
};

export default Title;
