import { useEffect, useRef, useState } from 'react';
import CategoriesList from '@/components/CategoriesList/CategoriesList.jsx';
import { useSelector } from 'react-redux';

const shadowStyle = {
  left: 0,
  bottom: 0,
  position: 'absolute',
  opacity: 1,
  width: '100%',
  paddingTop: '160px',
  boxShadow: 'inset 0 - 140px 55px -61px #faf9f5',
};

const Header = ({ background, smallBackground = null, showCategories = true, categories = [], subcategories = [], children }) => {
  const [maxScroll, setMaxScroll] = useState(900);
  const [innerHeight, setInnerHeight] = useState(0);
  const innerRef = useRef(null);
  const { categories: categoriesList } = useSelector((state) => state.categoryStore);

  useEffect(() => {
    const updateMaxScroll = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setMaxScroll(2400); // Mobile
      } else if (width < 1024) {
        setMaxScroll(1900); // Tablet
      } else if (width < 1280) {
        setMaxScroll(1600); // Desktop
      } else {
        setMaxScroll(900); // Large
      }
    };

    const updateInnerHeight = () => {
      if (innerRef.current) {
        setInnerHeight(innerRef.current.offsetHeight);
      }
    };

    updateMaxScroll();
    updateInnerHeight();
    window.addEventListener('resize', updateMaxScroll);
    window.addEventListener('resize', updateInnerHeight);
    return () => {
      window.removeEventListener('resize', updateMaxScroll);
      window.removeEventListener('resize', updateInnerHeight);
    };
  }, [maxScroll]);

  useEffect(() => {
    if (innerRef.current) {
      setInnerHeight(innerRef.current.offsetHeight);
    }
  }, [categories, categoriesList, subcategories]);

  const bg = window.innerWidth < 768 && smallBackground ? smallBackground : background;

  return (
    <div className='relative flex flex-col' style={{ height: innerHeight }}>
      <div
        className={`absolute inset-0 z-5 bg-cover bg-scroll bg-no-repeat object-cover text-center lg:bg-fixed`}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div ref={innerRef} className='z-5 flex flex-col bg-black bg-opacity-50'>
          {children}
          {showCategories ? <CategoriesList textColor={'#fff'} /> : <div className='mb-52'></div>}
        </div>
      </div>

      <div className='opacity-1 bottom-0 left-0 z-5 w-full pt-80 shadow-inner-xl' style={shadowStyle}></div>
    </div>
  );
};

export default Header;
