import { useEffect, useRef } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { FiBookmark } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import NavigationLinks from '../navbar/NavLinks.jsx';
import { del } from '../../utils/localStorage.js';
import { logoutUser } from '../../store/userSlice.jsx';
import smallLogo from '../../assets/eRentWhite.png';
import { toast } from 'react-toastify';
import { isLoggedIn } from '../../utils/auth';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SmallSidebar = ({ showSideBar, setShowSideBar }) => {
  const { navLinks, navLinksDropDown } = NavigationLinks();
  const { i18n, t } = useTranslation();
  const lng = JSON.parse(localStorage.getItem('lng')) ?? 'sr';
  const sideRef = useRef();

  const dispatch = useDispatch();

  const handleUserLogout = async () => {
    dispatch(logoutUser());
    await del('er_saved');

    setShowSideBar(false);
    toast.success('Successfully logged out');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideRef.current && !sideRef.current.contains(event.target)) {
        setShowSideBar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [showSideBar]);

  useEffect(() => {
    if (showSideBar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showSideBar]);

  return (
    <div
      ref={sideRef}
      className={`fixed left-0 top-0 z-50 ${
        !showSideBar ? 'left-[-100%]' : 'left-0'
      } s h-[100svh] w-[280px] bg-[#181818] px-[20px] pb-[20px] pt-[30px] duration-300 ease-in-out lg:w-[340px]`}
    >
      {' '}
      <AiOutlineClose
        size={22}
        onClick={() => setShowSideBar(!showSideBar)}
        className='absolute right-1 top-1 cursor-pointer text-primary-yellow lg:hidden'
      />
      <div className='flex h-full w-full flex-col'>
        <a
          onClick={() => {
            document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' });
          }}
          className='mx-auto mt-5 max-h-[60px] max-w-[198px]'
        >
          <img src={smallLogo} alt='logo' className='h-full w-full object-cover ' />
        </a>
        <div className='flex flex-col pt-10'>
          {navLinks.map((navlink) => {
            const { id, text, path, icon } = navlink;
            return (
              <Link
                className='flex flex-row items-center rounded-md py-[10px] pl-[5px] pr-[20px] text-left text-[16px] font-medium text-white hover:bg-primary-yellow md:text-xl'
                to={path}
                key={id}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
                  setShowSideBar(!showSideBar);
                }}
              >
                {icon}
                {text}
              </Link>
            );
          })}

          <div className='mx-auto mt-[15px] flex gap-5'>
            <select
              aria-label='language'
              name='language'
              className='bg-[#181818] font-medium text-white outline-none md:text-xl'
              defaultValue={lng}
              onChange={(e) => {
                if (e.target.value === 'sr') {
                  localStorage.setItem('lng', JSON.stringify('sr'));
                  i18n.changeLanguage('sr');
                } else if (e.target.value === 'en') {
                  localStorage.setItem('lng', JSON.stringify('en'));
                  i18n.changeLanguage('en');
                }
              }}
            >
              <option value='sr'>SR</option>

              <option value='en'>EN</option>
            </select>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
                  setShowSideBar(!showSideBar);
                }}
                to={isLoggedIn() ? '/saved' : '/login'}
              >
                <FiBookmark size={25} className='mb-1' color='white' />
              </Link>
            </div>
          </div>
        </div>
        <div className='mt-auto flex flex-col'>
          {navLinksDropDown.map((navLinkDropDown) => {
            const { id, text, path, icon } = navLinkDropDown;
            return (
              <Link
                className='my-[3px] flex flex-row  items-center rounded-md bg-[#474747] py-[10px] pl-[5px] pr-[20px] text-left font-medium text-white md:text-xl'
                to={path}
                key={id}
                onClick={navLinkDropDown.text === t('signOut') ? () => handleUserLogout() : ''}
              >
                {icon}
                {text}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SmallSidebar;
