import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import getSlugText from '@/utils/getSlugText.js';
import { Link } from 'react-router-dom';
import { getCategoryKey, getCategoryImage } from '@/utils/category.js';

const CategoriesList = ({ textColor }) => {
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state.categoryStore);
  return (
    <div className='z-5 mx-auto mb-0 mt-20 grid w-full max-w-[450px] grid-cols-1 items-start gap-4 pb-40 break4:grid-cols-2 sm:max-w-[760px] sm:grid-cols-3 sm:pb-24 lg:mb-32 lg:mt-32 lg:pb-10 xl:max-w-[1300px] xl:grid-cols-6 xl:gap-1'>
      {categories &&
        categories.map((category) => (
          <div key={category.name} className='z-5 flex cursor-pointer flex-col items-center justify-center'>
            <Link
              to={`/products/${getSlugText(t(getCategoryKey(category.name)))}`}
              onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' })}
            >
              <div
                className='h-48 w-48 rounded-xl border-4 border-primary-yellow bg-cover'
                style={{ backgroundImage: `url(${getCategoryImage(category.name)})` }}
              />
              <div className={`inline-block w-48 overflow-hidden text-center text-sm font-semibold uppercase`} style={{ color: textColor }}>
                {t(getCategoryKey(category.name))}
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default CategoriesList;
