import { useTranslation } from 'react-i18next';
import Header from '@/components/Header/Header.jsx';
import background from '@/assets/main-about.jpg';
import backgroundSmall from '@/assets/main-about-small.jpg';
import Title from '@/components/Title/Title.jsx';

const AboutPageHeader = () => {
  const { t } = useTranslation();
  return (
    <Header background={background} smallBackground={backgroundSmall}>
      <Title title={t('firstSection.smallTitle')} className='mb-8 mt-20 justify-center' />
      <h1 className='mb-80 mt-40 text-center text-3xl font-medium text-primary-yellow md:mb-6 md:text-5xl xl:mb-14 xl:text-7xl'>
        {t('aboutUs')}
      </h1>
    </Header>
  );
};

export default AboutPageHeader;
