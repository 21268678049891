import Dropdown from '../dropdown/Dropdown';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { IoMenuOutline } from 'react-icons/io5';
// import { MdGridView } from 'react-icons/md';

const Search = ({ numberOfAds, className = '', filterObject, setFilterObject, setSearchText, searchText }) => {
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState('sort');

  return (
    <div className={twMerge(className)}>
      {/* <div className='flex flex-col justify-normal p-0 pl-[1.625rem] pt-3 max-2xl:pr-[1.625rem] sm:flex-row sm:justify-between md:mt-[2.875rem] md:pt-0'> */}
      <div className='pt-3 break55:mt-[2.875rem] break55:pt-0'>
        {/* <h1 className='pb-[1.3125rem] pl-[1.625rem] pt-[1.065rem] text-center  text-[0.875rem] leading-[1.125rem] text-[#2C2C2C] sm:pr-3 sm:text-left'> */}
        <h1 className='pb-[1.3125rem] pl-[1.625rem] pt-[1.065rem]  text-[0.875rem] leading-[1.125rem] text-[#2C2C2C] sm:pr-3 sm:text-left'>
          {numberOfAds} {t('results')}
        </h1>
      </div>
      {/* <div className='flex h-[3.88rem] flex-row  place-items-center px-6  text-[1.13rem] font-light leading-[1.375rem] text-[#999999] max-2xl:pr-[1.625rem]'> */}
      <div className='grid w-full items-center gap-4  text-[1.13rem] font-medium leading-[1.375rem] text-black md:grid break9:flex'>
        <input
          type='text'
          name='search'
          id='#search'
          placeholder={`${t('placeholder')}`}
          // className='mr-3 h-14 w-full rounded-[0.5rem] px-5 shadow-md'
          className='w-full rounded-[0.5rem] py-[17px] pl-[1.625rem] pr-5 shadow-md'
          onChange={(e) => setSearchText(e?.target?.value)}
          value={searchText}
        />
        {/* <div className='flex items-center justify-center gap-2 sm:justify-normal'> */}
        <div className='flex gap-2'>
          {/* <div className=' w-[60%] sm:w-48'> */}
          <div className=' w-full break9:w-48'>
            <Dropdown
              // className='h-14 w-full justify-around gap-2 text-base font-light shadow-md '
              className='h-14 w-full justify-between gap-2 pr-4 text-base font-light shadow-md'
              dropdownClass='w-full z-50 top-16 px-2 py-2'
              titleClass='pl-8 break9:pl-4'
              // dropdownClass='w-full'
              options={
                <>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      setTextValue('priceToLow');
                      setFilterObject({ ...filterObject, sort: 'priceFrom', order: 1 });
                    }}
                  >
                    {t('priceToLow')}
                  </li>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      setTextValue('priceToHigh');
                      setFilterObject({ ...filterObject, sort: 'priceFrom', order: -1 });
                    }}
                  >
                    {t('priceToHigh')}
                  </li>
                  <li
                    className='w-full rounded-md px-2 hover:shadow-md'
                    onClick={() => {
                      setTextValue('newest');
                      setFilterObject({ ...filterObject, sort: 'createAt', order: -1 });
                    }}
                  >
                    {t('newest')}
                  </li>
                </>
              }
              text={t(textValue)}
            />
          </div>
          {/* <MdGridView className='h-[2.8rem] w-[2.8rem] rounded-lg bg-white p-2 shadow-md' />
          <IoMenuOutline className='h-[2.8rem] w-[2.8rem] rounded-lg bg-white p-2 shadow-md' /> */}
        </div>
      </div>
    </div>
  );
};

export default Search;
