import { useTranslation } from 'react-i18next';
import Header from '@/components/Header/Header.jsx';
import background from '@/assets/main-contact.jpg';

const ContactPageHeader = () => {
  const { t } = useTranslation();

  return (
    <Header background={background}>
      <div className='grid place-items-center py-[7.125rem]'>
        <h1 className='mb-10 text-center text-3xl font-medium text-primary-yellow md:mb-6 md:text-5xl xl:mb-14 xl:text-7xl'>
          {t('contact')}
        </h1>
      </div>
    </Header>
  );
};

export default ContactPageHeader;
