import ContactPageHeader from './components/contactPageHeader/ContactPageHeader';
import ContactPageForm from './components/contactPageForm/ContactPageForm';

const ContactPage = () => {
  return (
    <main className='w-full'>
      <ContactPageHeader />
      <ContactPageForm />
    </main>
  );
};

export default ContactPage;
